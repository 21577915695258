import React from "react"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Img from "gatsby-image"

import FadeIn from "react-fade-in"

import EventItem from "../components/events/eventItem"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"

const EventPage = ({ intl, data }) => {
  let strapiNode
  switch (intl.locale) {
    case "en":
      if (data.allStrapiEnEvent.edges[0]) {
        strapiNode = data.allStrapiEnEvent.edges[0].node
      }
      break
    case "ko":
      if (data.allStrapiKoEvent.edges[0]) {
        strapiNode = data.allStrapiKoEvent.edges[0].node
      }
      break
    case "cn":
      if (data.allStrapiCnEvent.edges[0]) {
        strapiNode = data.allStrapiCnEvent.edges[0].node
      }
      break
    default:
      break
  }

  if (!strapiNode) {
    return <></>
  }

  return (
    <Layout page="events">
      <SEO lang={intl.locale} title={strapiNode.title} />
      <Container fluid>
        <h1>{strapiNode.title}</h1>
        <br />
        <h3>{`${strapiNode.date}    ${strapiNode.location}`}</h3>
        <br />
        <p>{strapiNode.content}</p>
      </Container>
    </Layout>
  )
}

export default injectIntl(EventPage)

export const query = graphql`
  query EventPageQuery($title: String!, $strapiId: Int!) {
    allStrapiKoEvent(
      filter: { title: { eq: $title }, strapiId: { eq: $strapiId } }
    ) {
      edges {
        node {
          strapiId
          title
          date
          location
          content
        }
      }
    }
    allStrapiEnEvent(
      filter: { title: { eq: $title }, strapiId: { eq: $strapiId } }
    ) {
      edges {
        node {
          strapiId
          title
          date
          location
          content
        }
      }
    }
    allStrapiCnEvent(
      filter: { title: { eq: $title }, strapiId: { eq: $strapiId } }
    ) {
      edges {
        node {
          strapiId
          title
          date
          location
          content
        }
      }
    }
  }
`
