import React from "react"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import { MdLocationOn } from "react-icons/md"
import Img from "gatsby-image"

const EventItem = ({
  intl,
  strapiId,
  title,
  date,
  location,
  summary,
  content,
  thumbnail,
}) => {
  let details
  let contact
  switch (intl.locale) {
    case "en":
      details = "Details"
      contact = "Contact Us"
      break
    case "ko":
      details = "자세한 내용"
      contact = "제휴문의"
      break
    case "cn":
      details = "细节"
      contact = "제휴문의"
      break
    default:
      break
  }

  // If thumbnail, show thumbnail else current placeholder icon
  let icon = (
    <Col
      sm="1"
      md="1"
      className="my-auto justify-content-center d-none d-md-block"
    >
      <MdLocationOn className="circle-icon" size="32" />
    </Col>
  )
  if (thumbnail) {
    icon = (
      <Col
        sm="1"
        md="1"
        className="my-auto justify-content-center d-none d-sm-block pl-0"
      >
        <Img fixed={thumbnail.childImageSharp.fixed} />
      </Col>
    )
  }

  let detailBlock = <></>

  if (content) {
    detailBlock = (
      <Col sm="2" md="2" className="my-auto justify-content-end d-sm-block">
        <Link
          to={`/events/${strapiId}-${title.toLowerCase().replace(/ /g, "-")}`}
        >
          <Button className="btn-filled">
            <span>{content}</span>
          </Button>
        </Link>
      </Col>
    )

    return (
      <div className="event-item">
        <Row>
          {icon}
          <Col sm="8" md="7" className="my-auto justify-content-end">
            <h1>{title}</h1>
            <p>{`${date || ""}    ${location || ""}`}</p>
            <p>{summary}</p>
          </Col>
          {detailBlock}
          <Col
            sm="2"
            md="2"
            className="my-auto d-none d-md-block justify-content-end"
          >
            <Link to="/contact">
              <Button className="btn-filled">
                <span>{contact}</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    )
  } else {
    return (
      <div className="event-item">
        <Row>
          {icon}
          <Col sm="9" md="9" className="my-auto justify-content">
            <h1>{title}</h1>
            <p>{`${date || ""}    ${location || ""}`}</p>
            <p>{summary}</p>
          </Col>
          {detailBlock}
          <Col
            sm="2"
            md="2"
            className="my-auto d-none d-md-block justify-content-end"
          >
            <Link to="/contact">
              <Button className="btn-filled">
                <span>{contact}</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    )
  }
}

export default injectIntl(EventItem)
